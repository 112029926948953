/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent,
} from "SourceComponent/NewsletterSubscription/NewsletterSubscription.component";
import { FieldType } from 'Component/Field/Field.config';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import newsletterIcon from 'Style/icons/newsletter-icon.svg';

import './NewsletterSubscription.override.style';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace Component/NewsletterSubscription/Component
 */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {

  renderActions() {
    return (
        <button
          type={ FieldType.SUBMIT }
          block="Button"
          mods={ { isHollow: true } }
          aria-label={ __('Submit') }
        >
            {/* { __('Subscribe') } */}
            <Image src={ newsletterIcon }  alt={ __('Subscribe') } ratio={ ImageRatio.IMG_CUSTOM } />
        </button>
    );
  }
  render() {
    return (
        <div block="NewsletterSubscription" elem="Wrapper">
          <div block="ContentWrapper">
            <div block="NewsletterSubscription" elem="Block">
              <div block="NewsletterSubscription" elem="Title">
                { __('Sign Up to our Newsletter') }
              </div>
              <div block="NewsletterSubscription" elem="Description">
                { __('Get all the latest information on Events, Sales and Offers.') }
              </div>
              { super.render() }
            </div>
          </div>
        </div>
    );
  }
}

export default NewsletterSubscriptionComponent;
