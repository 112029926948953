/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import {
    FooterComponent as SourceFooterComponent,
} from "SourceComponent/Footer/Footer.component";
  
import { Component } from 'react';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import ContentWrapper from 'Component/ContentWrapper';
import CmsBlock from 'Component/CmsBlock';
import ExpandableContent from 'Component/ExpandableContent';

import './Footer.override.style';

/** @namespace Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {

  
  
  renderColumns() {
    return (
        <ContentWrapper
          isNotSection
          wrapperMix={ { block: 'Footer', elem: 'Columns' } }
          label=""
        >
          <ExpandableContent
            heading={ __('Essential Information') }
            mix={ { block: 'Footer', elem: 'ColumnContent' } }
            isArrow
          >
            <CmsBlock identifier='footer-essential-information' />
          </ExpandableContent>
          <ExpandableContent
            heading={ __('Shopping On Radian Online') }
            mix={ { block: 'Footer', elem: 'ColumnContent' } }
            isArrow
          >
            <CmsBlock identifier="footer-shopping-radian-online" />
          </ExpandableContent>
          <ExpandableContent
            heading={ __('Useful Links') }
            mix={ { block: 'Footer', elem: 'ColumnContent' } }
            isArrow
          >
            <CmsBlock identifier="footer-useful-links" />
          </ExpandableContent>
          <ExpandableContent
            heading={ __('Get Our Mobile Apps') }
            mix={ { block: 'Footer', elem: 'ColumnContent' } }
            isArrow
          >
            <CmsBlock identifier="footer-contact-information" />
          </ExpandableContent>
          {/* <div block="Footer" elem="Column">
            <div block="Footer" elem="ColumnTitle">{ __('Essential Information') }</div>
            <div block="Footer" elem="ColumnContent">
              <CmsBlock identifier='footer-essential-information' />
            </div>
          </div> */}
          {/* <div block="Footer" elem="Column">
            <div block="Footer" elem="ColumnTitle">{ __('Shopping On Radian Online') }</div>
            <div block="Footer" elem="ColumnContent">
              <CmsBlock identifier="footer-shopping-radian-online" />
            </div>
          </div>
          <div block="Footer" elem="Column">
            <div block="Footer" elem="ColumnTitle">{ __('Useful Links') }</div>
            <div block="Footer" elem="ColumnContent">
              <CmsBlock identifier="footer-useful-links" />
            </div>
          </div>
          <div block="Footer" elem="Column">
            <div block="Footer" elem="ColumnTitle">{ __('Get Our Mobile Apps') }</div>
            <div block="Footer" elem="ColumnContent">
              <CmsBlock identifier="footer-contact-information" />
            </div>
          </div> */}
        </ContentWrapper>
    );
  }
  renderCopyrightContent() {
    const { copyright } = this.props;

    return (
      <ContentWrapper
        mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
        wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
        label=""
      >
          <span block="Footer" elem="Copyright">
              { copyright }
          </span>
      </ContentWrapper>
    );
  }
  render() {
    const { isVisibleOnMobile, device } = this.props;

    if (!isVisibleOnMobile && device.isMobile) {
        return null;
    }

    if (isVisibleOnMobile && !device.isMobile) {
        return null;
    }

    return (
        <RenderWhenVisible>
            <footer block="Footer" aria-label="Footer">
                { this.renderNewsletterSubscriptionBlock() }
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        </RenderWhenVisible>
    );
  }


}

export default FooterComponent;
