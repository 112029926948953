/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent,
} from "SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component";


import './FieldNumberWithControls.override.style.scss';
/**
 * Field Number With Controls
 * @class FieldNumberWithControls
 * @namespace Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    
}

export default FieldNumberWithControlsComponent;
