/* eslint-disable */

import Link from 'Component/Link';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import {
    HtmlComponent as SourceHtml
} from 'SourceComponent/Html/Html.component';

export class HtmlComponent extends SourceHtml {

    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {

            const isSpecialLink = (value) => /^(sms|tel|mailto):/i.test(value);

            if(!isSpecialLink(href)){
                return (
                    <Link
                        onClick={this.scrollToTopFunction}
                        {...attributesToProps({ ...attrs, to: href })}
                    >
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }
        }

        return domToReact(children, this.parserOptions);
    }

}

export default HtmlComponent;